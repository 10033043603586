.overview{
    display: flex;
    margin: 2% 0 0 17%;
}
.aside{
    width: 18vw;
}
.hr{
    transform: rotateY(90 deg);
    margin-left: 2%;
}
input{
    width: 60vw;
    height: 6vh;
    border: none;
    border-radius: 50px;
    background: #ccc;
}
input::placeholder{
    padding-left: 3em;
}
.section{
    margin-top: 3%;
}
.boxes{
    display: flex;
}
.box{
    border: 1px solid #ccc;
    margin-right: 3%;
    padding: 0.5em;
    width: 15.5vw;
}
.graph{
    margin-top: 3%;
    border: 1px solid #ccc;
    width: 60.2vw;
    height: 35vh;
}
.graph-top{
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
}
.graph-top-left{
    display: flex;
}
.graph-top-left p{
    padding-right: 0.5em;
}
.products{
    margin-top: 3%;
    border: 1px solid #ccc;
    padding: 0.5em;
    width: 60vw;
}
.table{
    width: 58vw;
    padding: 0.5em;
}
thead{
    background: rgb(214, 210, 210);
    height: 3vh;
}
td{
    padding-left: 2.5em;
}
.object{
    height: 5vh;
    width: 2vw;
}
.input{
    display: flex;
}
.search{
    position: relative;
    left: 3%;
    margin-top: 1.5%;
}
.top{
    display: flex;
    justify-content: space-between;
}
.bag{
    color: orange;
}
.pie{
    color: plum;
}
.visitors{
    color: lightgreen;
}
.cart{
    color: red;
}
.aside-top{
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.bell, .pencil{
    border: 1px solid #000;
    border-radius: 50%;
    height: 4vh;
}
.count{
    display: flex;
    border: 1px solid #eee;
    padding: 1em;
    width: 13vw;
    height: 8vh;
    margin: 3% 0 0 15%;
}
.product, .followers{
    display: flex;
    padding: 0.3em;
}
.number p{
    font-size: 10px;
    color: #ddd;
    padding-left: 1em;
}
.shopping, .users{
    background: #ccc;
    padding: .3em;
}
.konter{
    height: 10vh;
    width: 5vw;
    background: lightskyblue;
    border-radius: 50%;
    margin-left: 30%;
}
figcaption{
    text-align: center;
    margin-top: 2%;
}
.reputation{
    margin-top: 5%;
}
.recent{
    margin-top: 5%;
}
.recent-top, .order{
    display: flex;
    justify-content: space-between;
}
.orders{
    border: 1px solid #ccc;
    padding: 1em;
}
.blue{
    color: lightblue;
}
.green{
    color: green;
}