.logo {
    margin: 10% 0 0 5%;
    height: 5vh;
    width: 3vw;
    background: lightskyblue;
    border-radius: 50%;
}
.nav-heading{
    display: flex;
}
.nav-heading p{
    margin: 12% 0 0 5%;
    color: lightskyblue;
}
.bx--side-nav__item{
    margin-top: 10%;
}
a.bx--side-nav__link[aria-current='page']{
    background-color: #fff;
}
.bx--side-nav__navigation{
    z-index: 0;
    border-right: 2px solid #eee;
    width: 15vw;
}
.bx--side-nav__link{
    margin-bottom: 5%;
}
.bx--side-nav__menu-item{
    padding: .1em;
}
svg{
    display: none;
}
.nav-icon{
    margin-right: 1em;
    color: #ccc;
}
@media only screen and (max-width: 640px){
    .sidenav{
        display: none;
    }
}